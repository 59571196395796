<script>
  import AtaApiService from '../../../services/domains/Ata'

  import bus from '../../../../utils/bus'
  import helpers from '../../../../mixins/helper'

  import { required } from 'vuelidate/lib/validators'
  import Swal from 'sweetalert2'
  import { clone } from 'lodash'

  // Services
  const ataService = new AtaApiService()

  export default {
    mixins: [helpers],
    data () {
      return {
        isSubmitted: false,
        isQrCodeExpired: false,
        form: {
          login: '',
          password: '',
          ref_ata: null,
          forma_assinatura: null,
          expira_em: null
        }
      }
    },
    validations: {
      form: {
        login: { required },
        password: { required }
      }
    },
    mounted () {
      this.form.ref_ata = parseInt(this.$route.params.id)
      this.form.forma_assinatura = [...atob(this.$route.query.via || '')].reverse().join('')
      this.form.expira_em = this.$route.query.exp || ''

      this.$nextTick(() => {
        this.$refs.login.focus()
      })

      bus.$emit('hide-loader')

      if (!this.form.expira_em || this.momentNow().unix() > parseInt([...atob(this.form.expira_em)].reverse().join(''))) {
        this.isQrCodeExpired = true
        Swal.fire('Atenção', 'Esse QR Code/URL expirou', 'warning')
      }
    },
    methods: {
      handleSubmit () {
        if (this.$v.form.$invalid) {
          Swal.fire('Atenção', 'Preencha a matrícula e a senha', 'warning')
          return
        }

        bus.$emit('show-loader', { message: 'Estamos verificando seus dados' })
        this.isSubmitted = true

        const formData = clone(this.form)

        ataService.assinar(formData)
          .then(() => {
            Swal.fire('Sucesso', 'Assinatura efetuada com sucesso!', 'success')

            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)

            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
      }
    }
  }
</script>

<template>
  <v-layout align-center justify-center class="bg-sign">
    <v-flex xs10 sm6 md3>
      <div class="text-xs-center pb-3 white--text">
        <h3 class="display-2 pb-1">Assinar ata</h3>
        <p>Preencha abaixo sua matrícula e senha, e após clique em assinar.</p>
      </div>
      <v-form light>
        <div class="login-form-item">
          <div class="icon">
            <i class="fas fa-user"></i>
          </div>
          <input type="tel"
                 v-model="form.login"
                 ref="login"
                 @keyup.enter="handleSubmit"
                 placeholder="Matrícula"
                 :disabled="isQrCodeExpired">
        </div>
        <div class="login-form-item">
          <div class="icon">
            <i class="fas fa-lock"></i>
          </div>
          <input type="password"
                 v-model="form.password"
                 ref="password"
                 @keyup.enter="handleSubmit"
                 placeholder="Senha"
                 :disabled="isQrCodeExpired">
        </div>
      </v-form>

      <v-btn class="white--text btn-login"
             large
             block
             :disabled="isSubmitted || isQrCodeExpired"
             @click="handleSubmit">ASSINAR
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<style src="../../../../assets/sass/login.scss" lang="scss"></style>
<style lang="scss">
.bg-sign {
  background: #3b5047;
}
</style>
