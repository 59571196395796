import List from './components/List'
import Form from './components/Form'

export default [
  {
    path: '/origem',
    component: List,
    name: 'origem.index',
    meta: {
      name: 'Origens',
      singularName: 'Origem'
    }
  },
  {
    path: '/origem/create',
    component: Form,
    name: 'origem.create',
    meta: {
      name: 'Criar origem',
      breadcrumb: [
        { name: 'Origens', to: 'origem.index' }
      ]
    }
  },
  {
    path: '/origem/:id',
    component: Form,
    name: 'origem.edit',
    meta: {
      name: 'Editando origem',
      breadcrumb: [
        { name: 'Origens', to: 'origem.index' }
      ]
    }
  }
]
