const basePath = process.env.VUE_APP_API_URL
const baseAPIGroup = 'api'
const APIVersion = 'v1'

// Export default configs
export default {
  appName: 'Gestão de Atas',
  basePath: basePath,
  baseAPIGroup: baseAPIGroup,
  APIVersion: APIVersion,
  fullAPIPath: [basePath, baseAPIGroup, APIVersion, ''].join('/')
}
