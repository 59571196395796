import RelatorioAtasPorParticipante from './components/RelatorioAtasPorParticipante'

export default [
  {
    path: '/relatorio/relatorio-atas-por-participante',
    component: RelatorioAtasPorParticipante,
    name: 'relatorio-atas-por-participante.index',
    meta: {
      name: 'Atas por participante',
      breadcrumb: [
        { name: 'Relatórios' }
      ]
    }
  }
]
