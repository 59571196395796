import Api from '../common/api/Api'

export default class UsuarioService extends Api {
  constructor () {
    super({ domain: 'usuario' })
  }

  async permissoes () {
    return this.get({ route: 'permissoes' })
  }

  async refreshToken () {
    return this.get({ route: 'refresh-token' })
  }
}
