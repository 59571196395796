<script>
  import AtaApiService from '../../../services/domains/Ata'
  import bus from '../../../../utils/bus'

  // Services
  const ataService = new AtaApiService()

  export default {
    name: 'PdfModal',
    components: {},
    props: {
      mostrarModal: {
        type: Boolean,
        default: false,
        required: true
      },
      ata: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        pdfBase64: '',
        localMostrarModal: this.mostrarModal
      }
    },
    watch: {
      mostrarModal (value) {
        if (value) {
          this.gerarPdfAta()
        }
      },
      localMostrarModal (value) {
        this.$emit('update:mostrar-modal', value)
      }
    },
    methods: {
      gerarPdfAta () {
        bus.$emit('show-loader')

        ataService.gerarPdf(this.ata)
          .then(async response => {
            this.pdfBase64 = 'data:application/pdf;base64,' + response
            this.localMostrarModal = true
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
      }
    }
  }
</script>

<template>
  <v-dialog v-model="localMostrarModal" fullscreen content-class="pdf-modal">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>PDF</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="localMostrarModal = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <iframe :src="pdfBase64"></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
