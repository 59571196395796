<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'app-sidebar',
    data () {
      return {
        menu: [
          {
            icon: 'fas fa-address-book',
            text: 'Atas',
            permissions: [
              'menu.ata',
              'menu.origem',
              'menu.tipo',
              'menu.local'
            ],
            children: [
              {
                icon: 'fas fa-address-book',
                text: 'Gerenciar atas',
                route: {
                  name: 'ata.index',
                  params: {}
                },
                permissions: ['menu.ata']
              },
              {
                icon: 'fas fa-building',
                text: 'Origens',
                route: {
                  name: 'origem.index',
                  params: {}
                },
                permissions: ['menu.origem']
              },
              {
                icon: 'fas fa-list',
                text: 'Tipos',
                route: {
                  name: 'tipo.index',
                  params: {}
                },
                permissions: ['menu.tipo']
              },
              {
                icon: 'fas fa-map-marker-alt',
                text: 'Locais',
                route: {
                  name: 'local.index',
                  params: {}
                },
                permissions: ['menu.local']
              }
            ]
          },
          {
            icon: 'fas fa-users',
            text: 'Usuários',
            route: {
              name: 'usuario.index',
              params: {}
            },
            permissions: ['menu.usuario']
          },
          {
            icon: 'fas fa-scroll',
            text: 'Perfis',
            route: {
              name: 'perfil.index',
              params: {}
            },
            permissions: ['menu.perfil']
          },
          {
            icon: 'fas fa-key',
            text: 'Permissões',
            route: {
              name: 'permissao.index',
              params: {}
            },
            permissions: ['menu.permissao']
          },
          {
            icon: 'fas fa-cog',
            text: 'Configurações',
            route: {
              name: 'configuracao.index',
              params: {}
            },
            permissions: ['menu.configuracao']
          },
          {
            icon: 'fas fa-chart-bar',
            text: 'Relatórios',
            permissions: ['menu.relatorio'],
            children: [
              {
                icon: 'far fa-circle',
                text: 'Atas por participante',
                route: {
                  name: 'relatorio-atas-por-participante.index',
                  params: {}
                },
                permissions: ['menu.relatorio-atas-por-participante']
              }
            ]
          }
        ]
      }
    },
    computed: {
      ...mapGetters(['showSidebar', 'currentUser', 'permissions']),
      drawer: {
        get: function () {
          return this.showSidebar
        },
        set: function (value) {
          this.toggleSidebar(value)
        }
      }
    },
    methods: {
      ...mapActions(['toggleSidebar']),
      can (permissions) {
        return this.permissions.some((item) => {
          return permissions.length === 0 || permissions.some(permission => permission === item)
        })
      }
    }
  }
</script>

<template>
  <v-navigation-drawer v-model="drawer"
                       class="sidebar-list"
                       fixed
                       clipped
                       app>

    <router-link :to="{name: 'home'}" class="sidebar-logo hidden-sm-and-up">
      <img src="https://urcamp.edu.br/site/img/logos/logo-preto.png" alt="">
    </router-link>

    <v-list>
      <template v-for="(item, index) in menu">
        <v-list-tile v-if="!item.children && can(item.permissions)" :to="{name: item.route.name}" :key="index">
          <v-list-tile-action>
            <v-icon color="orange darken-1">{{ item.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>{{ item.text }}</v-list-tile-title>
        </v-list-tile>

        <v-list-group v-if="item.children && can(item.permissions)" value="true" :key="index">
          <v-list-tile slot="activator">
            <v-list-tile-action>
              <v-icon color="orange darken-3">{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>{{ item.text }}</v-list-tile-title>
          </v-list-tile>

          <template v-for="(child, childIndex) in item.children">
            <router-link class="v-list__tile v-list__tile--link theme--light"
                         exact-active-class="primary--text v-list__tile--active"
                         :to="{name: child.route.name}"
                         :key="childIndex"
                         exact
                         v-if="can(child.permissions)">
              <v-list-tile-action>
                <v-icon color="orange darken-1">{{ child.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-title>{{ child.text }}</v-list-tile-title>
            </router-link>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<style src="../../../assets/sass/sidebar.scss" lang="scss"></style>
