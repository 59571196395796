<script>
  import AppPageHeader from '../../../../app/components/main/PageHeader.vue'
  import AppCard from '../../../../app/components/common/Card.vue'
  import AddParticipantsModal from '@/app/domains/ata/components/AddParticipantsModal'
  import MailModal from '@/app/domains/ata/components/MailModal'
  import PdfModal from '@/app/domains/ata/components/PdfModal'
  import { mapGetters } from 'vuex'
  import bus from '@/utils/bus'
  import helpers from '@/mixins/helper'
  import * as notifyService from '../../../services/common/notify'
  import AtaApiService from '../../../services/domains/Ata'
  import ParticipanteApiService from '../../../services/domains/Participante'
  import QrcodeVue from 'qrcode.vue'
  import Swal from 'sweetalert2'

  // Services
  const ataService = new AtaApiService()
  const participanteService = new ParticipanteApiService()

  export default {
    components: {
      AppPageHeader,
      AppCard,
      AddParticipantsModal,
      MailModal,
      PdfModal,
      QrcodeVue
    },
    mixins: [helpers],
    data () {
      return {
        isLoadingData: true,
        item: {
          origem: {},
          tipo: {},
          local: {},
          usuario: {},
          usuario_finalizado: {},
          participantes: [],
          dt_finalizada: null
        },
        mostrarFinalizar: false,
        mostrarReabrir: false,
        mostrarGerarPdf: false,
        mostrarEnviarPdf: false,
        mostrarInserirParticipantes: false,
        signBaseUrl: null,
        signUrl: null,
        signInterval: null,
        qrcode: {
          value: '',
          size: 1024,
          level: 'H'
        },
        mostrarModalQrCode: false,
        mostrarModalPdf: false,
        mostrarModalEnviarPdf: false,
        mostrarModalInserirParticipantes: false,
        enviarPdfFinalizar: true
      }
    },
    computed: {
      ...mapGetters(['permissions']),
      canEdit () {
        return this.permissions.some((item) => item === 'ata.update')
      },
      canFinalizar () {
        return this.permissions.some((item) => item === 'ata.finalizar')
      },
      canReabrir () {
        return this.permissions.some((item) => item === 'ata.reabrir')
      },
      canGerarPdf () {
        return this.permissions.some((item) => item === 'ata.gerar-pdf')
      },
      canEnviarPdf () {
        return this.permissions.some((item) => item === 'ata.enviar-pdf')
      },
      canInserirParticipantes () {
        return this.permissions.some((item) => item === 'ata.inserir-participantes')
      },
      canDestroyParticipante () {
        return this.permissions.some((item) => item === 'participante.destroy')
      }
    },
    async mounted () {
      const id = this.$route.params.id || null
      await this.loadItem(id)
      bus.$emit('hide-loader')
    },
    beforeDestroy () {
      this.stopSignInterval()
    },
    methods: {
      async loadItem (id) {
        return new Promise((resolve, reject) => {
          if (id) {
            ataService.get({
              id: id,
              relations: ['origem', 'tipo', 'local', 'usuario', 'usuario_finalizado', 'participantes.pessoa']
            })
              .then(response => {
                this.item = { ...response }
                this.isLoadingData = false

                this.signBaseUrl = window.location.protocol + '//' + window.location.host + '/ata/' + this.item.id + '/assinar'
                this.montarAcoes()
                resolve()
              })
              .catch(e => {
                console.log(e)
                this.isLoadingData = false
                reject(e)
              })
          } else {
            this.isLoadingData = false
            resolve()
          }
        })
      },
      finalizarAta () {
        Swal.fire({
          title: 'Deseja realmente finalizar essa ata?',
          text: 'Ao confirmar, não será possível receber novas assinaturas e nem reabrir!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#4caf50',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, continuar!',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (!result.value) {
            return
          }

          bus.$emit('show-loader')

          ataService.finalizar(this.item.id, this.enviarPdfFinalizar).then(async response => {
            this.item = { ...response }
            this.montarAcoes()

            bus.$emit('hide-loader')
            Swal.fire('Sucesso', 'Ata finalizada com sucesso!', 'success')
          }).catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
        })
      },
      reabrirAta () {
        bus.$emit('show-loader')

        ataService.reabrir(this.item.id)
          .then(async response => {
            this.item = { ...response }
            this.montarAcoes()

            bus.$emit('hide-loader')
            Swal.fire('Sucesso', 'Ata reaberta com sucesso!', 'success')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
      },
      montarAcoes () {
        this.mostrarFinalizar = false
        this.mostrarReabrir = false
        this.mostrarGerarPdf = false
        this.mostrarEnviarPdf = false
        this.mostrarInserirParticipantes = false

        this.stopSignInterval()
        this.generateSignUrl()

        if (!this.item.dt_finalizada) {
          this.mostrarFinalizar = true

          if (this.item.fl_lista_presenca) {
            this.mostrarInserirParticipantes = true
          }

          this.startSignInterval()
        } else {
          this.mostrarReabrir = true
          this.mostrarGerarPdf = true
          this.mostrarEnviarPdf = true
        }

        if (this.item.fl_lista_presenca) {
          this.enviarPdfFinalizar = false
        }
      },
      toggleAmpliarQrCode () {
        this.mostrarModalQrCode = !this.mostrarModalQrCode
      },
      onCopySignUrl () {
        notifyService.success({
          message: 'URL para assinatura copiada'
        })
      },
      onDestroyParticipante (participante) {
        Swal.fire({
          title: 'Deseja realmente remover esse participante?',
          text: 'Essa ação não pode ser revertida!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#4caf50',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, continuar!',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (!result.value) {
            return
          }

          bus.$emit('show-loader')

          participanteService.destroy({ id: participante.id }).then(() => {
            this.item.participantes.splice(this.item.participantes.findIndex(p => p.id === participante.id), 1)

            bus.$emit('hide-loader')
            Swal.fire('Sucesso', 'Participante removido com sucesso!', 'success')
          }).catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
        })
      },
      generateSignUrl () {
        const exp = this.momentNow().add(3, 'minutes').unix()

        this.signUrl = this.signBaseUrl + '?via=' + btoa([...'url'].reverse().join('')) + '&exp=' + btoa([...exp.toString()].reverse().join(''))
        this.qrcode.value = this.signBaseUrl + '?via=' + btoa([...'qrcode'].reverse().join('')) + '&exp=' + btoa([...exp.toString()].reverse().join(''))
      },
      startSignInterval () {
        this.signInterval = setInterval(() => {
          this.generateSignUrl()
        }, 90000)
      },
      stopSignInterval () {
        clearInterval(this.signInterval)
        this.signInterval = null
      }
    }
  }
</script>

<template>
  <div class="page-content">

    <app-page-header back-route-name="ata.index"/>

    <app-card title="Dados da Ata" icon="fas fa-file-alt">
      <template slot="content">
        <v-container grid-list-lg v-if="!isLoadingData">
          <v-layout row wrap>
            <v-flex xs12 md10>
              <strong>ID:</strong> {{ item.id }}<br>
              <strong>Origem:</strong> {{ item.origem.nome }}<br>
              <strong>Lista de presença:</strong> {{ item.fl_lista_presenca ? 'Sim' : 'Não' }}<br>
              <strong>Título:</strong> {{ item.titulo }}<br>
              <strong>Tipo:</strong> {{ item.tipo.nome }}<br>
              <strong>Data reunião:</strong> {{ item.dt_reuniao | dateEnToBr }}<br>
              <strong>Hora reunião:</strong> {{ item.hora_reuniao }}<br>
              <strong>Local:</strong> {{ item.local.nome }}<br>
              <strong>Criado em:</strong> {{ item.dt_criacao | dateTimeEnToBr }}<br>
              <strong>Criado por:</strong> {{ item.usuario.nome | ucwords }}<br>
              <strong>Finalizado em:</strong> {{ item.dt_finalizada | dateTimeEnToBr }}<br>
              <strong>Finalizado por:</strong> {{
                item.usuario_finalizado ? item.usuario_finalizado.nome : '' | ucwords
              }}<br>
              <strong>Assunto previsto:</strong><br>
              <div class="html-editor-text" v-html="item.assunto_previsto"></div>
              <strong>Assunto tratado:</strong><br>
              <div class="html-editor-text" v-html="item.assunto_tratado"></div>
            </v-flex>
            <v-flex xs12 md2>
              <v-btn depressed color="info" large block
                     :to="{ name: 'ata.edit', params: { id: item.id } }"
                     v-if="canEdit && !item.dt_finalizada">
                <i class="fas fa-edit"></i>
                <span class="pl-2">Editar</span>
              </v-btn>

              <v-btn depressed color="primary" large block
                     @click="mostrarModalPdf = true"
                     v-if="mostrarGerarPdf && canGerarPdf">
                <i class="fas fa-file-pdf"></i>
                <span class="pl-2">Gerar PDF</span>
              </v-btn>

              <v-btn depressed color="primary" large block
                     @click="mostrarModalEnviarPdf = true"
                     v-if="mostrarEnviarPdf && canEnviarPdf">
                <i class="fas fa-mail-bulk"></i>
                <span class="pl-2">Enviar PDF</span>
              </v-btn>

              <v-divider class="my-3"></v-divider>

              <div class="qrcode-wrapper">
                <qrcode-vue class="qrcode" :value="qrcode.value" :size="qrcode.size" :level="qrcode.level"
                            render-as="svg"/>
              </div>

              <v-btn depressed large block @click="toggleAmpliarQrCode">
                <i class="fas fa-qrcode"></i>
                <span class="pl-2">Ampliar QR Code</span>
              </v-btn>

              <v-btn depressed
                     large
                     block
                     v-clipboard:copy="signUrl"
                     v-clipboard:success="onCopySignUrl"
                     title="Copiar URL para assinatura">
                <i class="fas fa-copy"></i>
                <span class="pl-2">Copiar URL</span>
              </v-btn>
            </v-flex>
            <v-flex xs12>
              <ul class="v-expansion-panel theme--light elevation-0">
                <li class="v-expansion-panel__container grey lighten-2 v-expansion-panel__container--active">
                  <div class="v-expansion-panel__header">
                    <div class="font-weight-bold shrink">Participantes</div>

                    <div>
                      <v-btn depressed color="info" small
                             @click="mostrarModalInserirParticipantes = true"
                             v-if="mostrarInserirParticipantes && canInserirParticipantes">
                        <i class="fas fa-plus-circle"></i>
                        <span class="pl-2">Inserir participantes</span>
                      </v-btn>
                    </div>
                  </div>
                  <div class="v-expansion-panel__body">
                    <div class="v-table__overflow">
                      <table class="v-datatable v-table theme--light">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Participante</th>
                          <th>E-mail</th>
                          <th v-if="item.fl_lista_presenca">Detalhes</th>
                          <th>Assinou em</th>
                          <th>Assinado via</th>
                          <th>Ações</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(participante, index) in item.participantes"
                            :key="`participante-${participante.id}`">
                          <td>{{ index + 1 }}</td>
                          <td>{{ participante.pessoa.nome + ' (' + participante.ref_pessoa + ')' }}</td>
                          <td>{{ participante.pessoa.email }}</td>
                          <td v-if="item.fl_lista_presenca">{{ participante.atribuicao }}</td>
                          <td>{{ participante.dt_assinatura | dateTimeEnToBr }}</td>
                          <td>{{ participante.forma_assinatura || '' }}</td>
                          <td class="list-table-nowrap">
                            <div class="btn-actions">
                              <v-btn depressed color="error" small
                                     v-if="canDestroyParticipante && !participante.dt_assinatura && !item.dt_finalizada"
                                     @click="onDestroyParticipante(participante)"
                                     title="Remover"
                                     class="mx-0">
                                <i class="fas fa-trash"></i>
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="!item.participantes.length">
                          <td :colspan="item.fl_lista_presenca ? 7 : 6" class="text-xs-center">
                            Nenhum participante encontrado.
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </li>
              </ul>

              <v-divider class="my-3"
                         v-if="(mostrarFinalizar && canFinalizar) || (mostrarReabrir && canReabrir)"></v-divider>

              <v-flex xs12 v-if="mostrarFinalizar && canFinalizar">
                <v-switch v-model="enviarPdfFinalizar"
                          label="Enviar PDF por e-mail ao finalizar"></v-switch>
              </v-flex>

              <v-btn class="ma-0 v-btn--x-large" depressed color="primary" block large
                     @click="finalizarAta"
                     v-if="mostrarFinalizar && canFinalizar">
                <i class="fas fa-door-closed"></i>
                <span class="pl-2">Finalizar</span>
              </v-btn>

              <v-btn class="ma-0 v-btn--x-large" depressed color="primary" block large outline
                     @click="reabrirAta"
                     v-if="mostrarReabrir && canReabrir">
                <i class="fas fa-door-open"></i>
                <span class="pl-2">Reabrir</span>
              </v-btn>
            </v-flex>
          </v-layout>

          <v-dialog v-model="mostrarModalQrCode" fullscreen content-class="qrcode-modal">
            <v-card>
              <v-toolbar dark color="primary">
                <v-toolbar-title>QR Code</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="toggleAmpliarQrCode">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <div class="qrcode-wrapper">
                  <qrcode-vue class="qrcode" :value="qrcode.value" :size="qrcode.size" :level="qrcode.level"
                              render-as="svg"/>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>

          <pdf-modal :mostrar-modal.sync="mostrarModalPdf" :ata="item.id"/>
          <mail-modal :mostrar-modal.sync="mostrarModalEnviarPdf" :ata="item.id"/>
          <add-participants-modal :mostrar-modal.sync="mostrarModalInserirParticipantes" :ata.sync="item"/>
        </v-container>
      </template>
    </app-card>

  </div>
</template>
