import Api from '../common/api/Api'

export default class AtaService extends Api {
  constructor () {
    super({ domain: 'ata' })
  }

  async finalizar (ata, enviarPdf) {
    if (enviarPdf === undefined) {
      enviarPdf = true
    }

    return this.get({ route: `${ata}/finalizar?enviar_pdf=${enviarPdf ? 1 : 0}` })
  }

  async reabrir (ata) {
    return this.get({ route: `${ata}/reabrir` })
  }

  async assinar (data) {
    return this.post({ route: 'assinar', data })
  }

  async gerarPdf (ata) {
    return this.get({ route: `${ata}/gerar-pdf` })
  }

  async enviarPdf (data) {
    return this.post({ route: 'enviar-pdf', data })
  }

  async inserirParticipantes (data) {
    return this.post({ route: 'inserir-participantes', data })
  }
}
