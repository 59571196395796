var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"column sortable",class:{
        'asc': _vm.itemDirection === 'ASC',
        'desc': _vm.itemDirection === 'DESC',
        'active': _vm.itemDirection,
        'text-xs-center': _vm.align === 'center',
        'text-xs-right': _vm.align === 'right',
        'text-xs-left': _vm.align === 'left'
    },on:{"click":_vm.changeSort}},[_vm._v(" "+_vm._s(_vm.label)+" "),_c('v-icon',[_vm._v("arrow_upward")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }