import axios from 'axios'
import ApiService from './api/Api'
import bus from '../../../utils/bus'
import helpers from '../../../mixins/helper'

const dataService = new ApiService({})

export default {
  async get (domains) {
    // Lista de requisições que serão executadas
    const promises = []
    // Lista de respostas das requisições
    const responses = {}

    if (domains !== undefined && domains.length > 0) {
      bus.$emit('show-loader')
      // Percorre a lista de domains solicitada
      for (const val of domains) {
        if (val !== undefined) {
          val.route = val.route || null
          const defaultData = {
            orderBy: [
              {
                column: 'nome',
                direction: 'ASC'
              }
            ]
          }
          let data = val.data || defaultData
          dataService.setDomain(val.domain)
          switch (val.domain) {
            case 'origem':
              promises.push(dataService.search({
                domain: 'origem',
                route: val.route,
                data,
                perPage: 9999
              }))
              responses.origens = []
              break
            case 'tipo':
              promises.push(dataService.search({
                domain: 'tipo',
                route: val.route,
                data,
                perPage: 9999
              }))
              responses.tipos = []
              break
            case 'local':
              promises.push(dataService.search({
                domain: 'local',
                route: val.route,
                data,
                perPage: 9999
              }))
              responses.locais = []
              break
            case 'usuario': {
              const usuarios = []

              data = {
                fl_ativo: { '=': 't' },
                orderBy: [
                  {
                    column: 'nome',
                    direction: 'ASC'
                  }
                ]
              }

              dataService.search({
                domain: 'usuario',
                route: val.route,
                data,
                perPage: 9999
              }).then(result => {
                result.data.forEach((usuario) => {
                  usuario.nome = helpers.methods.ucwords(usuario.nome)
                  usuarios.push(usuario)
                })
              })

              promises.push(new Promise(resolve => resolve(usuarios)))
              responses.usuarios = []
              break
            }
            case 'permissao':
              promises.push(dataService.search({
                domain: 'permissao',
                route: val.route,
                data,
                perPage: 9999
              }))
              responses.permissoes = []
              break
            case 'perfil':
              promises.push(dataService.search({
                domain: 'perfil',
                route: val.route,
                data,
                perPage: 9999
              }))
              responses.perfis = []
              break
            case 'participante': {
              promises.push(dataService.get({ domain: 'participante' }))
              responses.participantes = []
              break
            }
            default:
              console.warn(`Data service: '${val.domain}' não encontrado`)
              break
          }
        }
      }
    }

    let loopCounter = 0

    /**
     * Realiza todas as requisições e atribui os resultados ao objeto response
     * @type {Promise<any>}
     */
    const data = axios.all(promises)
      .then((result) => {
        for (const index in responses) {
          if (result[loopCounter] !== undefined) {
            if (result[loopCounter].data !== undefined) {
              responses[index] = result[loopCounter].data
            } else {
              responses[index] = result[loopCounter]
            }
          } else {
            responses[index] = []
          }
          loopCounter++
        }
        return responses
      })

    /**
     * Retorna uma nova Promise com o resultado das requisições
     * Exemplo de retorno:
     * {
     *      clientes: [],
     *      produtos: []
     *  }
     */
    return new Promise((resolve) => {
      resolve(data)
    })
  }
}
