import app from '../app/routes'

// Rotas default da aplicação
const root = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'ata.index' }
  }
]

export default [...root, ...app]
