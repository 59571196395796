import List from './components/List'
import Form from './components/Form'
import Show from './components/Show'
import Sign from './components/Sign'

export default [
  {
    path: '/ata',
    component: List,
    name: 'ata.index',
    meta: {
      name: 'Atas',
      singularName: 'Ata'
    }
  },
  {
    path: '/ata/create',
    component: Form,
    name: 'ata.create',
    meta: {
      name: 'Criar ata',
      breadcrumb: [
        { name: 'Atas', to: 'ata.index' }
      ]
    }
  },
  {
    path: '/ata/:id/edit',
    component: Form,
    name: 'ata.edit',
    meta: {
      name: 'Editando ata',
      breadcrumb: [
        { name: 'Atas', to: 'ata.index' }
      ]
    }
  },
  {
    path: '/ata/:id',
    component: Show,
    name: 'ata.show',
    meta: {
      name: 'Visualizando ata',
      breadcrumb: [
        { name: 'Atas', to: 'ata.index' }
      ]
    }
  },
  {
    path: '/ata/:id/assinar',
    component: Sign,
    name: 'ata.sign',
    meta: {
      name: 'Assinar ata',
      auth: false
    }
  }
]
