<script>
  const defaultLabel = 'Descrição'

  export default {
    name: 'html-editor',
    props: {
      label: {
        type: String,
        default: defaultLabel
      },
      model: {
        type: String,
        default: ''
      },
      height: {
        type: String,
        default: '350px'
      },
      hasError: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        hasFocus: false,
        editorOptions: {
          placeholder: defaultLabel + '...',
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ['link'],
              ['clean']
            ]
          }
        }
      }
    },
    computed: {
      quill () {
        return this.$refs.myQuillEditor && this.$refs.myQuillEditor.quill
      },
      content: {
        get: function () {
          return this.model
        },
        set: function (newValue) {
          this.$emit('update:model', newValue)
        }
      }
    },
    methods: {
      onEditorBlur ($event) {
        this.hasFocus = false
      },
      onEditorFocus ($event) {
        this.hasFocus = true
      }
    }
  }
</script>

<template>
  <div class="form-editor-container">
    <div class="form-editor"
         :class="{'-focus': hasFocus, '-error': hasError}">
      <label class="editor-label">{{ label }}</label>
      <quill-editor v-model="content"
                    ref="myQuillEditor"
                    :options="editorOptions"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    :style="'height:' + height">
      </quill-editor>
    </div>
    <div class="v-text-field__details" v-if="hasError">
      <div class="v-messages theme--light error--text">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">Esse campo é obrigatório</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.form-editor {
  padding-bottom: 50px;

  .editor-label {
    display: block;
    line-height: 28px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
  }

  .ql-toolbar.ql-snow {
    border-color: rgba(0, 0, 0, 0.3);
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-color: rgba(0, 0, 0, 0.3);
  }

  &.-focus {
    .editor-label {
      color: #465e54;
    }

    .ql-toolbar.ql-snow {
      border-color: #465e54;
    }

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-color: #465e54;
    }

  }

  &.-error {
    .editor-label {
      color: #ff5252;
    }

    .ql-toolbar.ql-snow {
      border-color: #ff5252;
    }

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-color: #ff5252;
    }

  }
}

@media all and (max-width: 768px) {
  .form-editor {
    padding-bottom: 120px;
  }
}
</style>
