import List from './components/List'
import Form from './components/Form'

export default [
  {
    path: '/local',
    component: List,
    name: 'local.index',
    meta: {
      name: 'Locais',
      singularName: 'Local'
    }
  },
  {
    path: '/local/create',
    component: Form,
    name: 'local.create',
    meta: {
      name: 'Criar local',
      breadcrumb: [
        { name: 'Locais', to: 'local.index' }
      ]
    }
  },
  {
    path: '/local/:id',
    component: Form,
    name: 'local.edit',
    meta: {
      name: 'Editando local',
      breadcrumb: [
        { name: 'Locais', to: 'local.index' }
      ]
    }
  }
]
