import '@fortawesome/fontawesome-free/css/all.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/dist/vuetify.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import Vue from 'vue'
import App from './app/components/main/App.vue'
import router from './router'
import store from './vuex'
import http from './http'
import Vuetify from 'vuetify'
import Vuelidate from 'vuelidate'
import VueQuillEditor from 'vue-quill-editor'
import VueClipboard from 'vue-clipboard2'

import './app/filters'

Vue.config.productionTip = false

Vue.use(http, {
  router,
  store
})
Vue.use(Vuetify, {
  theme: {
    primary: '#465e54'
  }
})
Vue.use(Vuelidate)
Vue.use(VueQuillEditor)
Vue.use(VueClipboard)

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
