<script>
  import AppPageHeader from '../../../../app/components/main/PageHeader.vue'
  import AppCard from '../../../../app/components/common/Card.vue'
  import FormSaveActions from '../../../../app/components/form/FormActions.vue'

  import UsuarioApiService from '../../../services/domains/Usuario'
  import PessoaApiService from '../../../services/domains/Pessoa'
  import DataService from '../../../services/common/data'
  import * as notifyService from '../../../services/common/notify'
  import bus from '../../../../utils/bus'
  import helpers from '../../../../mixins/helper'

  import { email, required } from 'vuelidate/lib/validators'
  import { clone, debounce, isNaN } from 'lodash'

  // Services
  const usuarioService = new UsuarioApiService()
  const pessoaService = new PessoaApiService()

  export default {
    components: {
      AppPageHeader,
      AppCard,
      FormSaveActions
    },
    mixins: [helpers],
    data () {
      return {
        isEdit: false,
        isSubmitted: false,
        isLoadingData: true,
        form: {
          ref_pessoa: null,
          nome: '',
          email: '',
          fl_ativo: true,
          perfis: [],
          permissoes: []
        },
        dependencies: {
          pessoas: [],
          perfis: [],
          permissoes: []
        },
        buscandoPessoa: false,
        pessoa: ''
      }
    },
    validations: {
      form: {
        ref_pessoa: { required },
        nome: { required },
        email: { email },
        fl_ativo: { required }
      }
    },
    watch: {
      pessoa: debounce(function (newValue) {
        if (!this.form.ref_pessoa && newValue) {
          this.buscarPessoa(newValue)
        }
      }, 400)
    },
    async mounted () {
      const id = this.$route.params.id || null
      await this.getDependencies()
      await this.loadItem(id)
      bus.$emit('hide-loader')
    },
    methods: {
      async getDependencies () {
        return new Promise(resolve => {
          DataService.get([{ domain: 'perfil' }, { domain: 'permissao' }]).then(result => {
            this.dependencies = { ...this.dependencies, ...result }
            resolve()
          })
        })
      },
      async loadItem (id) {
        if (id) {
          this.isEdit = true
          usuarioService.get({
            id: id,
            relations: ['pessoa', 'perfis', 'permissoes']
          })
            .then(response => {
              this.form = { ...response }
              this.pessoa = { ...response.pessoa }
              this.dependencies.pessoas.push(response.pessoa)
              this.form.perfis = response.perfis.map((perfil) => perfil.id)
              this.form.permissoes = response.permissoes.map((permissao) => permissao.id)
              this.isLoadingData = false
            })
            .catch(e => {
              console.log(e)
              this.isLoadingData = false
            })
        } else {
          this.isLoadingData = false
        }
      },
      onSave () {
        this.$v.form.$touch()

        if (this.$v.form.$invalid) {
          return
        }

        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = clone(this.form)
        usuarioService.save({ data: data })
          .then(() => {
            notifyService.success({ hideLoader: true })
            this.isSubmitted = false
            this.$router.push({ name: 'usuario.index' })
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
      },
      buscarPessoa (pessoa) {
        return new Promise((resolve, reject) => {
          this.buscandoPessoa = true

          const searchData = { data: {} }

          if (!isNaN(parseInt(pessoa))) {
            searchData.data.id = { '=': pessoa }
          } else {
            searchData.data.nome = { ilike: '%' + pessoa + '%' }
          }

          searchData.perPage = 10

          pessoaService.search(searchData)
            .then((response) => {
              this.dependencies.pessoas = response.data
              this.buscandoPessoa = false
              resolve()
            })
            .catch(e => {
              console.log(e)
              this.buscandoPessoa = false
              reject(e)
            })
        })
      },
      filtrarBuscarPessoa (item, queryText, itemText) {
        const id = item.id.toString()
        const nome = item.nome.toLowerCase()

        queryText = queryText.toString().toLowerCase()

        return id === queryText || nome.indexOf(queryText) > -1
      },
      extrairDadosPessoa (refPessoa) {
        if (refPessoa) {
          const pessoa = this.dependencies.pessoas.find((item) => item.id === refPessoa)

          this.form.nome = pessoa.nome
          this.form.email = pessoa.email_oficial
        }
      },
      filtrarPerfil (item, queryText, itemText) {
        return item.nome.toLowerCase().indexOf(queryText.toString().toLowerCase()) > -1
      },
      filtrarPermissao (item, queryText, itemText) {
        return item.nome.toLowerCase().indexOf(queryText.toString().toLowerCase()) > -1
      }
    }
  }
</script>

<template>
  <div class="page-content">

    <app-page-header/>

    <app-card title="Dados do Cadastro" icon="fas fa-file-alt">
      <template slot="content">
        <v-form :model="form">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <v-autocomplete v-model="form.ref_pessoa"
                                :loading="buscandoPessoa"
                                :items="dependencies.pessoas"
                                :search-input.sync="pessoa"
                                :filter="filtrarBuscarPessoa"
                                :error-messages="validationMessageField($v.form.ref_pessoa)"
                                @change="extrairDadosPessoa"
                                item-text="nome"
                                item-value="id"
                                label="Pessoa"
                                hint="Digite a matrícula ou nome da pessoa"
                                persistent-hint
                                hide-no-data
                                no-filter
                                clearable></v-autocomplete>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="form.email"
                              :error-messages="validationMessageField($v.form.email, ['email'])"
                              label="E-mail"></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-autocomplete v-model="form.perfis"
                                :items="dependencies.perfis"
                                :filter="filtrarPerfil"
                                item-text="nome"
                                item-value="id"
                                label="Perfis"
                                hide-no-data
                                clearable
                                chips
                                deletable-chips
                                multiple
                                hint="Selecione os perfis do usuário"
                                persistent-hint></v-autocomplete>
              </v-flex>
              <v-flex xs12>
                <v-autocomplete v-model="form.permissoes"
                                :items="dependencies.permissoes"
                                :filter="filtrarPermissao"
                                item-text="nome"
                                item-value="id"
                                label="Permissões"
                                hide-no-data
                                clearable
                                chips
                                deletable-chips
                                multiple
                                hint="Selecione as permissões do usuário"
                                persistent-hint></v-autocomplete>
              </v-flex>
              <v-flex xs12>
                <v-switch v-model="form.fl_ativo"
                          :error-messages="validationMessageField($v.form.fl_ativo)"
                          label="Ativo"></v-switch>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>

      </template>
    </app-card>

    <form-save-actions :event-click="onSave"
                       :is-disabled="isSubmitted"
                       :is-submitted="isSubmitted"
                       route-back-name="usuario.index"/>

  </div>
</template>
