<script>
  import AtaApiService from '../../../services/domains/Ata'
  import bus from '../../../../utils/bus'
  import { mapGetters } from 'vuex'
  import MailModal from '@/app/domains/ata/components/MailModal'
  import PdfModal from '@/app/domains/ata/components/PdfModal'
  import Swal from 'sweetalert2'
  import ListShowButton from '@/app/components/list/ShowButton'
  import ListEditButton from '@/app/components/list/EditButton'
  import ListDeleteButton from '@/app/components/list/DeleteButton'

  // Services
  const ataService = new AtaApiService()

  export default {
    components: {
      MailModal,
      PdfModal,
      ListShowButton,
      ListEditButton,
      ListDeleteButton
    },
    props: {
      ata: {
        type: Object
      }
    },
    data () {
      return {
        isLoadingData: true,
        localAta: this.ata,
        mostrarFinalizar: false,
        mostrarReabrir: false,
        mostrarGerarPdf: false,
        mostrarEnviarPdf: false,
        mostrarModalPdf: false,
        mostrarModalEnviarPdf: false,
        mostrarModalParticipantes: false
      }
    },
    watch: {
      localAta (value) {
        this.$emit('update:ata', value)
      }
    },
    mounted () {
      this.montarAcoes()
    },
    computed: {
      ...mapGetters(['permissions']),
      canFinalizar () {
        return this.permissions.some((item) => item === 'ata.finalizar')
      },
      canReabrir () {
        return this.permissions.some((item) => item === 'ata.reabrir')
      },
      canGerarPdf () {
        return this.permissions.some((item) => item === 'ata.gerar-pdf')
      },
      canEnviarPdf () {
        return this.permissions.some((item) => item === 'ata.enviar-pdf')
      }
    },
    methods: {
      visualizarParticipantesAta () {
        bus.$emit('show-loader')

        ataService.get({
          id: this.localAta.id,
          relations: ['tipo', 'local', 'usuario', 'usuario_finalizado', 'participantes.pessoa']
        })
          .then(async response => {
            this.localAta = { ...response }
            this.montarAcoes()

            this.mostrarModalParticipantes = true
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
      },
      finalizarAta () {
        Swal.fire({
          title: 'Deseja realmente finalizar essa ata?',
          text: 'Ao confirmar, não será possível receber novas assinaturas e nem reabrir!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#4caf50',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, continuar!',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (!result.value) {
            return
          }

          bus.$emit('show-loader')

          ataService.finalizar(this.localAta.id, !this.localAta.fl_lista_presenca).then(async response => {
            this.localAta = { ...response }
            this.montarAcoes()

            bus.$emit('hide-loader')
            Swal.fire('Sucesso', 'Ata finalizada com sucesso!', 'success')
          }).catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
        })
      },
      reabrirAta () {
        bus.$emit('show-loader')

        ataService.reabrir(this.localAta.id)
          .then(async response => {
            this.localAta = { ...response }
            this.montarAcoes()

            bus.$emit('hide-loader')
            Swal.fire('Sucesso', 'Ata reaberta com sucesso!', 'success')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
      },
      montarAcoes () {
        this.mostrarFinalizar = false
        this.mostrarReabrir = false
        this.mostrarGerarPdf = false
        this.mostrarEnviarPdf = false

        if (!this.localAta.dt_finalizada) {
          this.mostrarFinalizar = true
        } else {
          this.mostrarReabrir = true
          this.mostrarGerarPdf = true
          this.mostrarEnviarPdf = true
        }
      }
    }
  }
</script>

<template>
  <div class="d-inline-block">
    <div class="d-inline-block">
      <v-btn color="secondary"
             @click="mostrarModalEnviarPdf = true"
             v-if="mostrarEnviarPdf && canEnviarPdf"
             title="Enviar PDF">
        <i class="fas fa-mail-bulk"></i>
      </v-btn>

      <v-btn color="secondary"
             @click="mostrarModalPdf = true"
             v-if="mostrarGerarPdf && canGerarPdf"
             title="Gerar PDF">
        <i class="fas fa-file-pdf"></i>
      </v-btn>

      <ListEditButton route-name="ata.edit" :item="localAta" domain="ata" v-if="!localAta.dt_finalizada"/>

      <v-btn color="secondary"
             @click="visualizarParticipantesAta"
             title="Ver participantes"
             v-if="!localAta.dt_finalizada">
        <i class="fas fa-users"></i>
      </v-btn>

      <ListShowButton route-name="ata.show" :item="localAta" domain="ata"/>

      <v-btn color="secondary"
             @click="finalizarAta"
             v-if="mostrarFinalizar && canFinalizar"
             title="Finalizar">
        <i class="fas fa-door-closed"></i>
      </v-btn>

      <v-btn color="secondary"
             @click="reabrirAta"
             v-if="mostrarReabrir && canReabrir"
             title="Reabrir">
        <i class="fas fa-door-open"></i>
      </v-btn>

      <ListDeleteButton :item="localAta" domain="ata" v-if="!localAta.dt_finalizada"/>
    </div>

    <pdf-modal :mostrar-modal.sync="mostrarModalPdf" :ata="localAta.id"/>
    <mail-modal :mostrar-modal.sync="mostrarModalEnviarPdf" :ata="localAta.id"/>

    <v-dialog v-model="mostrarModalParticipantes" :fullscreen="$vuetify.breakpoint.smAndDown" persistent width="80%">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Participantes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="mostrarModalParticipantes = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="v-table__overflow">
            <table class="v-datatable v-table theme--light text-xs-left">
              <thead>
              <tr>
                <th>#</th>
                <th>Participante</th>
                <th>E-mail</th>
                <th v-if="localAta.fl_lista_presenca">Detalhes</th>
                <th>Assinou em</th>
                <th>Assinado via</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(participante, index) in localAta.participantes" :key="`participante-${participante.id}`">
                <td>{{ index + 1 }}</td>
                <td>{{ participante.pessoa.nome + ' (' + participante.ref_pessoa + ')' }}</td>
                <td>{{ participante.pessoa.email }}</td>
                <td v-if="localAta.fl_lista_presenca">{{ participante.atribuicao }}</td>
                <td>{{ participante.dt_assinatura | dateTimeEnToBr }}</td>
                <td>{{ participante.forma_assinatura || '' }}</td>
              </tr>
              <tr v-if="!localAta.participantes.length">
                <td :colspan="localAta.fl_lista_presenca ? 6 : 5" class="text-xs-center">
                  Nenhum participante encontrado.
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
