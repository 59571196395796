import { routes as login } from './domains/login'
import { routes as configuracao } from './domains/configuracao'
import { routes as perfil } from './domains/perfil'
import { routes as permissao } from './domains/permissao'
import { routes as tipo } from './domains/tipo'
import { routes as local } from './domains/local'
import { routes as origem } from './domains/origem'
import { routes as ata } from './domains/ata'
import { routes as usuario } from './domains/usuario'
import { routes as relatorio } from './domains/relatorio'

export default [
  ...login,
  ...configuracao,
  ...perfil,
  ...permissao,
  ...tipo,
  ...local,
  ...origem,
  ...ata,
  ...usuario,
  ...relatorio
]
