<script>
  import AppPageHeader from '../../../../app/components/main/PageHeader.vue'
  import AppCard from '../../../../app/components/common/Card.vue'
  import FormSaveActions from '../../../../app/components/form/FormActions.vue'

  import helpers from '../../../../mixins/helper'
  import AtaApiService from '../../../services/domains/Ata'
  import DataService from '@/app/services/common/data'
  import bus from '@/utils/bus'
  import { clone } from 'lodash'
  import * as notifyService from '@/app/services/common/notify'
  import { required, requiredIf } from 'vuelidate/lib/validators'
  import HtmlEditor from '../../../components/common/HtmlEditor'
  import store from '@/vuex'

  // Services
  const ataService = new AtaApiService()

  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

  export default {
    components: {
      AppPageHeader,
      AppCard,
      FormSaveActions,
      HtmlEditor
    },
    mixins: [helpers],
    data () {
      return {
        isEdit: false,
        isSubmitted: false,
        isLoadingData: true,
        form: {
          ref_origem: '',
          fl_lista_presenca: false,
          titulo: '',
          ref_tipo: '',
          dt_reuniao: null,
          hora_reuniao: null,
          ref_local: null,
          assunto_previsto: '',
          assunto_tratado: ''
        },
        dependencies: {
          origens: [],
          tipos: [],
          locais: []
        },
        mostrarPickerDtReuniao: false,
        mostrarPickerHoraReuniao: false,
        dataReuniaoMinimaPermitida: null,
        listeningAssuntoPrevisto: false,
        srAssuntoPrevisto: null,
        srAssuntoPrevistoBtnTitle: 'Iniciar transcrição',
        listeningAssuntoTratado: false,
        srAssuntoTratado: null,
        srAssuntoTratadoBtnTitle: 'Iniciar transcrição',
        lastEasterEgg: ''
      }
    },
    validations: {
      form: {
        ref_origem: { required },
        fl_lista_presenca: { required },
        ref_tipo: { required },
        dt_reuniao: { required },
        hora_reuniao: { required },
        ref_local: { required },
        assunto_previsto: {
          required: requiredIf(function (form) {
            return !form.fl_lista_presenca
          })
        }
      }
    },
    computed: {
      dt_reuniao_formatada () {
        return this.form.dt_reuniao ? this.dateFormat(this.form.dt_reuniao, 'br') : ''
      },
      titulo_placeholder () {
        return this.form.fl_lista_presenca ? 'LISTA DE PRESENÇA' : 'ATA DE REUNIÃO'
      },
      isSpeechRecognitionAvailable () {
        return !!SpeechRecognition
      }
    },
    async mounted () {
      const id = this.$route.params.id || null
      await this.getDependencies()
      await this.loadItem(id)
      bus.$emit('hide-loader')
    },
    methods: {
      async getDependencies () {
        return new Promise(resolve => {
          DataService.get([
            { domain: 'origem' },
            { domain: 'tipo' },
            { domain: 'local' },
            { domain: 'usuario' }
          ]).then(result => {
            this.dependencies.origens = result.origens
            this.dependencies.tipos = result.tipos
            this.dependencies.locais = result.locais
            this.dependencies.usuarios = result.usuarios
            resolve()
          })
        })
      },
      async loadItem (id) {
        return new Promise((resolve, reject) => {
          if (id) {
            this.isEdit = true
            ataService.get({
              id: id,
              relations: []
            })
              .then(response => {
                this.form = { ...response }
                this.dataReuniaoMinimaPermitida = this.form.dt_reuniao
                this.isLoadingData = false

                resolve()
              })
              .catch(e => {
                console.log(e)
                this.isLoadingData = false
                reject(e)
              })
          } else {
            this.isLoadingData = false
            this.form.dt_reuniao = this.momentNow().format('YYYY-MM-DD')
            this.form.hora_reuniao = this.momentNow().format('HH:mm')
            resolve()
          }
        })
      },
      onSave () {
        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = clone(this.form)

        if (data.fl_lista_presenca) {
          data.assunto_previsto = ''
          data.assunto_tratado = ''
        }

        ataService.save({ data: data })
          .then((response) => {
            notifyService.success({ hideLoader: true })
            this.isSubmitted = false
            this.$router.push({
              name: 'ata.show',
              params: { id: response.id }
            })
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
      },
      filtrarUsuario (item, queryText, itemText) {
        return item.nome.toLowerCase().includes(queryText.toString().toLowerCase())
      },
      datasReuniaoPermitidas (value) {
        if (!this.isEdit) {
          return value >= helpers.methods.momentNow().format('YYYY-MM-DD')
        } else if (value === this.dataReuniaoMinimaPermitida || value >= helpers.methods.momentNow().format('YYYY-MM-DD')) {
          return true
        }

        return false
      },
      toggleSpeechToTextAssuntoPrevisto () {
        if (!this.listeningAssuntoPrevisto) {
          this.srAssuntoPrevisto = SpeechRecognition ? new SpeechRecognition() : false
          this.srAssuntoPrevisto.lang = 'pt-BR'
          this.srAssuntoPrevisto.continuous = true
          this.srAssuntoPrevisto.interimResults = true
          this.srAssuntoPrevisto.maxAlternatives = 1

          this.srAssuntoPrevisto.onresult = (event) => {
            const result = event.results[event.results.length - 1]
            const transcript = result[0].transcript
            const easterEggMessage = this.transcriptionEasterEggs(transcript)

            if (transcript.toLowerCase().includes('parar transcrição')) {
              this.srAssuntoPrevisto.stop()
            } else if (easterEggMessage && easterEggMessage !== this.lastEasterEgg) {
              this.lastEasterEgg = easterEggMessage

              store.dispatch('showNotification', {
                title: 'Ops...',
                message: easterEggMessage,
                type: 'warning',
                timeout: 5000
              })
            } else if (result.isFinal) {
              this.form.assunto_previsto += transcript
            }
          }

          this.srAssuntoPrevisto.onstart = () => {
            this.srAssuntoPrevistoBtnTitle = 'Parar transcrição'
          }

          this.srAssuntoPrevisto.onend = () => {
            this.listeningAssuntoPrevisto = false
            this.srAssuntoPrevistoBtnTitle = 'Iniciar transcrição'
          }

          this.srAssuntoPrevisto.start()
          this.listeningAssuntoPrevisto = true
        } else {
          this.srAssuntoPrevisto.stop()
          this.listeningAssuntoPrevisto = false
        }
      },
      toggleSpeechToTextAssuntoTratado () {
        if (!this.listeningAssuntoTratado) {
          this.srAssuntoTratado = SpeechRecognition ? new SpeechRecognition() : false
          this.srAssuntoTratado.lang = 'pt-BR'
          this.srAssuntoTratado.continuous = true
          this.srAssuntoTratado.interimResults = false
          this.srAssuntoTratado.maxAlternatives = 1

          this.srAssuntoTratado.onresult = (event) => {
            const result = event.results[event.results.length - 1]
            const transcript = result[0].transcript
            const easterEggMessage = this.transcriptionEasterEggs(transcript)

            if (transcript.toLowerCase().includes('parar transcrição')) {
              this.srAssuntoTratado.stop()
            } else if (easterEggMessage && easterEggMessage !== this.lastEasterEgg) {
              this.lastEasterEgg = easterEggMessage

              store.dispatch('showNotification', {
                title: 'Ops...',
                message: easterEggMessage,
                type: 'warning',
                timeout: 5000
              })
            } else if (result.isFinal) {
              this.form.assunto_tratado += transcript
            }
          }

          this.srAssuntoTratado.onstart = () => {
            this.srAssuntoTratadoBtnTitle = 'Parar transcrição'
          }

          this.srAssuntoTratado.onend = () => {
            this.listeningAssuntoTratado = false
            this.srAssuntoTratadoBtnTitle = 'Iniciar transcrição'
          }

          this.srAssuntoTratado.start()
          this.listeningAssuntoTratado = true
        } else {
          this.srAssuntoTratado.stop()
          this.listeningAssuntoTratado = false
        }
      },
      transcriptionEasterEggs (transcript) {
        if (transcript.toLowerCase().includes('fernando way')) {
          return 'Entende das coisas :)'
        } else if (transcript.toLowerCase().includes('guga love') || transcript.toLowerCase().includes('guguinha')) {
          return 'Sim guguinha. Nós te amamos também :)'
        } else if (transcript.toLowerCase().includes('moisés tricolor')) {
          return 'Gremista até os ossos... Até a pé nós iremos / Para o que der e vier... :)'
        } else if (transcript.toLowerCase().includes('milano kit kat') || transcript.toLowerCase().includes('milano kitkat')) {
          return 'Mãezona de gatos :)'
        } else if (transcript.toLowerCase().includes('joão basqueteiro')) {
          return 'Tem um futuro talentoso :)'
        }

        return ''
      }
    }
  }
</script>

<template>
  <div class="page-content">

    <app-page-header/>

    <app-card title="Dados do Cadastro" icon="fas fa-file-alt">
      <template slot="content">
        <v-form :model="form" v-if="!isLoadingData">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <v-select v-model="form.ref_origem"
                          :items="dependencies.origens"
                          :error-messages="validationMessageField($v.form.ref_origem)"
                          label="Origem"
                          item-text="nome"
                          item-value="id"></v-select>
              </v-flex>
              <v-flex xs12>
                <v-switch v-model="form.fl_lista_presenca"
                          :error-messages="validationMessageField($v.form.fl_lista_presenca)"
                          label="Lista de presença"></v-switch>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="form.titulo"
                              :placeholder="titulo_placeholder"
                              label="Título"></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-select v-model="form.ref_tipo"
                          :items="dependencies.tipos"
                          :error-messages="validationMessageField($v.form.ref_tipo)"
                          label="Tipo"
                          item-text="nome"
                          item-value="id"></v-select>
              </v-flex>
              <v-flex xs12 md6>
                <v-select v-model="form.ref_local"
                          :items="dependencies.locais"
                          :error-messages="validationMessageField($v.form.ref_local)"
                          label="Local"
                          item-text="nome"
                          item-value="id"></v-select>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field :value="dt_reuniao_formatada"
                              label="Data reunião"
                              append-icon="event"
                              readonly
                              v-if="!isEdit"/>
                <v-menu ref="mostrarPickerDtReuniao"
                        :close-on-content-click="false"
                        v-model="mostrarPickerDtReuniao"
                        :nudge-right="40"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        max-width="290px"
                        min-width="290px"
                        v-if="isEdit">
                  <v-text-field slot="activator"
                                :value="dt_reuniao_formatada"
                                :error-messages="validationMessageField($v.form.dt_reuniao)"
                                label="Data reunião"
                                append-icon="event"
                                clearable
                                readonly
                                @click:clear="form.dt_reuniao = null"/>
                  <v-date-picker v-model="form.dt_reuniao"
                                 no-title
                                 @input="mostrarPickerDtReuniao = false"
                                 locale="pt-br"
                                 :allowed-dates="datasReuniaoPermitidas"/>
                </v-menu>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field v-model="form.hora_reuniao"
                              label="Hora reunião"
                              append-icon="access_time"
                              readonly
                              v-if="!isEdit"/>
                <v-menu ref="mostrarPickerHoraReuniao"
                        v-model="mostrarPickerHoraReuniao"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="form.hora_reuniao"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        max-width="290px"
                        min-width="290px"
                        v-if="isEdit">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="form.hora_reuniao"
                                  :error-messages="validationMessageField($v.form.hora_reuniao)"
                                  label="Hora reunião"
                                  append-icon="access_time"
                                  clearable
                                  readonly
                                  v-on="on"/>
                  </template>
                  <v-time-picker v-if="mostrarPickerHoraReuniao"
                                 v-model="form.hora_reuniao"
                                 format="24hr"
                                 full-width
                                 @click:minute="$refs.mostrarPickerHoraReuniao.save(form.hora_reuniao)"/>
                </v-menu>
              </v-flex>
              <v-flex xs12 v-if="!form.fl_lista_presenca">
                <v-btn depressed class="ml-0" @click="toggleSpeechToTextAssuntoPrevisto"
                       v-if="isSpeechRecognitionAvailable" :disabled="listeningAssuntoTratado">
                  <i class="fas fa-microphone"></i>
                  <span class="pl-2">{{ srAssuntoPrevistoBtnTitle }}</span>
                </v-btn>

                <html-editor :model.sync="form.assunto_previsto"
                             label="Assunto previsto"
                             height="160px"
                             :has-error="$v.form.assunto_previsto.$invalid && $v.form.assunto_previsto.$dirty"/>
              </v-flex>
              <v-flex xs12 v-if="!form.fl_lista_presenca">
                <v-btn depressed class="ml-0" @click="toggleSpeechToTextAssuntoTratado"
                       v-if="isSpeechRecognitionAvailable" :disabled="listeningAssuntoPrevisto">
                  <i class="fas fa-microphone"></i>
                  <span class="pl-2">{{ srAssuntoTratadoBtnTitle }}</span>
                </v-btn>

                <html-editor :model.sync="form.assunto_tratado" label="Assunto tratado" height="320px"/>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </template>
    </app-card>

    <form-save-actions :event-click="onSave"
                       :is-disabled="isSubmitted || !!(isEdit && form.dt_finalizada)"
                       :is-submitted="isSubmitted"
                       route-back-name="ata.index"/>

  </div>
</template>
