<script>
  import AppPageHeader from '../../../../app/components/main/PageHeader.vue'
  import ListMain from '../../../../app/components/list/Main.vue'
  import ListSortColumn from '../../../../app/components/list/SortColumn.vue'
  import ListCustomActions from './ListCustomActions'

  import helpers from '../../../../mixins/helper'
  import bus from '../../../../utils/bus'
  import { clone } from 'lodash'
  import { mapGetters } from 'vuex'

  import DataService from '../../../services/common/data'

  const defaultSearchData = {}
  const defaultSort = [{
    column: 'id',
    direction: 'DESC'
  }]

  export default {
    components: {
      AppPageHeader,
      ListMain,
      ListSortColumn,
      ListCustomActions
    },
    mixins: [helpers],
    data () {
      return {
        isLoadingData: true,
        filters: {
          origem: '',
          lista_presenca: null,
          tipo: '',
          local: '',
          usuario: '',
          finalizada: null,
          participante: ''
        },
        dependencies: {
          origens: [],
          itensFiltroListaPresenca: [
            {
              text: 'Sim',
              value: true
            },
            {
              text: 'Não',
              value: false
            }
          ],
          tipos: [],
          locais: [],
          usuarios: [],
          itensFiltroFinalizada: [
            {
              text: 'Sim',
              value: true
            },
            {
              text: 'Não',
              value: false
            }
          ],
          participantes: []
        }
      }
    },
    async mounted () {
      this.listStore.listSort = clone(defaultSort)

      bus.$emit('list-init', {
        domain: 'ata',
        relations: ['origem:id,nome', 'tipo', 'local', 'usuario', 'participantes.pessoa'],
        data: clone(defaultSearchData)
      }, () => {
        this.isLoadingData = false
      })

      await this.getDependencies()
    },
    computed: {
      ...mapGetters(['listStore', 'permissions']),
      canQualquerUsuario () {
        return this.permissions.some((item) => item === 'ata.qualquer-usuario')
      }
    },
    methods: {
      async getDependencies () {
        const domains = [{ domain: 'origem' }, { domain: 'tipo' }, { domain: 'local' }, { domain: 'participante' }]

        if (this.canQualquerUsuario) {
          domains.push({ domain: 'usuario' })
        }

        DataService.get(domains).then(result => {
          this.dependencies.origens = result.origens
          this.dependencies.tipos = result.tipos
          this.dependencies.locais = result.locais
          this.dependencies.usuarios = result.usuarios || []
          this.dependencies.participantes = result.participantes || []
        })
      },
      onFilter () {
        const searchData = {}

        if (this.filters.origem) {
          searchData.ref_origem = this.filters.origem
        }

        if (this.filters.lista_presenca !== null) {
          searchData.fl_lista_presenca = { '=': this.filters.lista_presenca }
        }

        if (this.filters.tipo) {
          searchData.ref_tipo = this.filters.tipo
        }

        if (this.filters.local) {
          searchData.ref_local = this.filters.local
        }

        if (this.filters.usuario) {
          searchData.ref_usuario = this.filters.usuario
        }

        if (this.filters.finalizada !== null) {
          searchData.finalizada = this.filters.finalizada
        }

        if (this.filters.participante) {
          searchData.ref_pessoa_participante = this.filters.participante
        }

        bus.$emit('list-filter', searchData)
      },
      onFilterClean () {
        this.listStore.listSort = clone(defaultSort)

        this.filters = { ...defaultSearchData }
        bus.$emit('list-filter-clean', this.filters)
      },
      onDestroy (item) {
        bus.$emit('list-destroy-item', item)
      },
      filtrarUsuario (item, queryText, itemText) {
        return item.nome.toLowerCase().indexOf(queryText.toString().toLowerCase()) > -1
      },
      filtrarParticipante (item, queryText, itemText) {
        return item.nome.toLowerCase().indexOf(queryText.toString().toLowerCase()) > -1
      }
    }
  }
</script>

<template>
  <div class="page-content">

    <app-page-header store-route-name="ata.create"/>

    <ListMain class="elevation-1">

      <div slot="filters">
        <v-form :model="filters" @submit.prevent="onFilter">
          <v-card class="list-filters-card" :flat="true">
            <v-card-title class="grey lighten-2">
              <div class="flex-header">
                <div class="flex-header-title">
                  <i class="fas fa-list"></i>
                  <span class="pl-2 font-weight-medium">Listagem</span>
                </div>
                <div class="flex-header-actions">
                  <v-btn type="submit" color="green lighten-1 white--text" small>
                    <i class="fas fa-filter"></i>
                    <span class="pl-2">Pesquisar</span>
                  </v-btn>
                  <v-btn @click="onFilterClean" small>
                    <i class="fas fa-times-circle"></i>
                    <span class="pl-2">Limpar Filtros</span>
                  </v-btn>
                </div>
              </div>
            </v-card-title>
            <v-card-text>
              <v-container class="list-filters-container" grid-list-md>
                <v-layout row wrap align-content-end>
                  <v-flex xs12 sm6 md3>
                    <v-select v-model="filters.origem"
                              :items="dependencies.origens"
                              label="Origem"
                              item-text="nome"
                              item-value="id"
                              clearable></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select v-model="filters.lista_presenca"
                              :items="dependencies.itensFiltroListaPresenca"
                              label="Lista de presença"
                              clearable
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select v-model="filters.tipo"
                              :items="dependencies.tipos"
                              label="Tipo"
                              item-text="nome"
                              item-value="id"
                              clearable></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select v-model="filters.local"
                              :items="dependencies.locais"
                              label="Local"
                              item-text="nome"
                              item-value="id"
                              clearable></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3 v-if="canQualquerUsuario">
                    <v-autocomplete v-model="filters.usuario"
                                    :items="dependencies.usuarios"
                                    :filter="filtrarUsuario"
                                    item-text="nome"
                                    item-value="id"
                                    label="Criado por"
                                    hide-no-data
                                    clearable></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select v-model="filters.finalizada"
                              :items="dependencies.itensFiltroFinalizada"
                              label="Finalizada"
                              clearable
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-autocomplete v-model="filters.participante"
                                    :items="dependencies.participantes"
                                    :filter="filtrarParticipante"
                                    item-text="nome"
                                    item-value="id"
                                    label="Participante"
                                    hide-no-data
                                    clearable></v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-form>
      </div>

      <div slot="list" v-if="!isLoadingData">
        <div class="v-table__overflow">
          <table class="v-datatable v-table theme--light">
            <thead>
            <tr>
              <list-sort-column label="ID" column="id"/>
              <th class="column">Origem</th>
              <th class="column">Lista de presença</th>
              <th class="column">Título</th>
              <th class="column">Tipo</th>
              <list-sort-column label="Data reunião" column="dt_reuniao"/>
              <th class="column">Hora reunião</th>
              <th class="column">Local</th>
              <th class="column" v-if="canQualquerUsuario">Criado por</th>
              <th class="column">Finalizada</th>
              <th class="btn-actions">Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in listStore.listData" :key="`item-${item.id}`">
              <td>{{ item.id }}</td>
              <td>{{ item.origem.nome }}</td>
              <td>{{ item.fl_lista_presenca ? 'Sim' : 'Não' }}</td>
              <td>{{ item.titulo }}</td>
              <td>{{ item.tipo.nome }}</td>
              <td>{{ item.dt_reuniao | dateEnToBr }}</td>
              <td>{{ item.hora_reuniao }}</td>
              <td>{{ item.local.nome }}</td>
              <td v-if="canQualquerUsuario">{{ (item.usuario ? item.usuario.nome : '') | ucwords }}</td>
              <td>
                {{ item.dt_finalizada ? 'Sim' + ' (' + dateTimeFormat(item.dt_finalizada, 'br', true) + ')' : 'Não' }}
              </td>
              <td class="list-table-nowrap">
                <div class="btn-actions">
                  <ListCustomActions :ata.sync="listStore.listData[index]"/>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </ListMain>
  </div>
</template>
