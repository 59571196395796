import Api from '../common/api/Api'

export default class RelatorioService extends Api {
  constructor () {
    super({ domain: 'relatorio' })
  }

  async gerarRelatorioAtasPorParticipante (data) {
    return this.post({ route: 'atas-por-participante', data })
  }

  async gerarRelatorioCsvAtasPorParticipante (data) {
    return this.post({ route: 'csv-atas-por-participante', data })
  }
}
