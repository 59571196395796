<script>
  import AppPageHeader from '../../../components/main/PageHeader.vue'
  import AppCard from '../../../components/common/Card.vue'
  import FormSaveActions from '../../../components/form/FormActions.vue'
  import VueBase64FileUpload from 'vue-base64-file-upload'

  import OrigemApiService from '../../../services/domains/Origem'
  import * as notifyService from '../../../services/common/notify'
  import bus from '../../../../utils/bus'
  import helpers from '../../../../mixins/helper'

  import { required } from 'vuelidate/lib/validators'
  import { clone } from 'lodash'
  import Swal from 'sweetalert2'

  // Services
  const origemService = new OrigemApiService()

  export default {
    components: {
      AppPageHeader,
      AppCard,
      FormSaveActions,
      VueBase64FileUpload
    },
    mixins: [helpers],
    data () {
      return {
        isEdit: false,
        isSubmitted: false,
        isLoadingData: true,
        form: {
          nome: '',
          titulo_cabecalho: '',
          subtitulo_cabecalho: '',
          imagem_cabecalho: ''
        },
        dependencies: {},
        uploadMaxFileSize: 0.025
      }
    },
    validations: {
      form: {
        nome: { required }
      }
    },
    async mounted () {
      const id = this.$route.params.id || null
      await this.loadItem(id)
      bus.$emit('hide-loader')
    },
    methods: {
      async loadItem (id) {
        if (id) {
          this.isEdit = true
          origemService.get({ id: id })
            .then(response => {
              this.form = { ...response }
              this.isLoadingData = false
            })
            .catch(e => {
              console.log(e)
              this.isLoadingData = false
            })
        } else {
          this.isLoadingData = false
        }
      },
      onSave () {
        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = clone(this.form)
        origemService.save({ data: data })
          .then(() => {
            notifyService.success({ hideLoader: true })
            this.isSubmitted = false
            this.$router.push({ name: 'origem.index' })
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
      },
      onSizeExceeded () {
        Swal.fire('Atenção', 'O tamanho máximo do arquivo é de ' + this.uploadMaxFileSize * 1000 + ' KB.', 'warning')
      },
      onLoad (dataUri) {
        this.form.imagem_cabecalho = dataUri
      },
      onDeleteImage () {
        this.form.imagem_cabecalho = ''
      }
    }
  }
</script>

<template>
  <div class="page-content">

    <app-page-header/>

    <app-card title="Dados do Cadastro" icon="fas fa-file-alt">
      <template slot="content">
        <v-form :model="form" @submit.prevent>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field v-model="form.nome"
                              :error-messages="validationMessageField($v.form.nome)"
                              label="Nome"/>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="form.titulo_cabecalho"
                              label="Título do cabeçalho"></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="form.subtitulo_cabecalho"
                              label="Subtítulo do cabeçalho"></v-text-field>
              </v-flex>
              <v-flex xs12>
                <div v-if="!form.imagem_cabecalho">
                  <vue-base64-file-upload accept="image/png,image/jpeg"
                                          input-class="uploader"
                                          :disable-preview="true"
                                          placeholder="Clique aqui para selecionar a imagem do cabeçalho *"
                                          :max-size="uploadMaxFileSize"
                                          @size-exceeded="onSizeExceeded"
                                          @load="onLoad"/>
                  <div class="mt-2">
                    * A imagem deve ter uma largura de 230px e uma altura de 80px
                  </div>
                </div>

                <div class="uploader-preview" v-if="form.imagem_cabecalho">
                  <img :src="form.imagem_cabecalho" width="230" alt="">
                  <v-btn depressed color="error" small
                         @click="onDeleteImage()"
                         class="mt-0">
                    <i class="fas fa-trash"></i> <span class="pl-2">Excluir imagem</span>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </template>
    </app-card>

    <form-save-actions :event-click="onSave"
                       :is-disabled="isSubmitted"
                       :is-submitted="isSubmitted"
                       route-back-name="origem.index"/>

  </div>
</template>
