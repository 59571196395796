<script>
  import AtaApiService from '../../../services/domains/Ata'
  import bus from '../../../../utils/bus'
  import Swal from 'sweetalert2'

  // Services
  const ataService = new AtaApiService()

  export default {
    name: 'MailModal',
    components: {},
    props: {
      mostrarModal: {
        type: Boolean,
        default: false,
        required: true
      },
      ata: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        localMostrarModal: this.mostrarModal,
        destinatarios: ''
      }
    },
    watch: {
      mostrarModal (value) {
        if (value) {
          this.localMostrarModal = true
        }
      },
      localMostrarModal (value) {
        this.$emit('update:mostrar-modal', value)
      }
    },
    methods: {
      enviarPdfAta () {
        bus.$emit('show-loader')

        ataService.enviarPdf({
          ref_ata: this.ata,
          destinatarios: this.destinatarios
        })
          .then(() => {
            Swal.fire('Sucesso', 'PDF enviado para os destinatários!', 'success')

            this.fecharModal()
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
      },
      fecharModal () {
        this.localMostrarModal = false
        this.destinatarios = ''
      }
    }
  }
</script>

<template>
  <v-dialog v-model="localMostrarModal" :fullscreen="$vuetify.breakpoint.smAndDown" persistent width="50%">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Enviar PDF por e-mail</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="fecharModal">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-alert :value="true" type="info" class="mb-3">
          Separe os e-mails dos destinatários por vírgula (,)...
        </v-alert>
        <v-textarea v-model="destinatarios"
                    label="Destinatários"
                    box
                    auto-grow
                    hide-details/>
      </v-card-text>
      <v-card-actions class="px-3">
        <v-spacer></v-spacer>
        <v-btn @click="enviarPdfAta"
               color="primary"
               class="elevation-0"
               :disabled="!destinatarios">
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
