<script>
  import AppPageHeader from '../../../../app/components/main/PageHeader.vue'

  import RelatorioApiService from '../../../services/domains/Relatorio'
  import helpers from '../../../../mixins/helper'
  import bus from '../../../../utils/bus'
  import { mapGetters } from 'vuex'
  import DataService from '@/app/services/common/data'

  // Services
  const relatorioService = new RelatorioApiService()

  export default {
    components: { AppPageHeader },
    mixins: [helpers],
    data () {
      return {
        isLoadingData: false,
        filters: {
          participante: '',
          origem: '',
          lista_presenca: null,
          finalizada: null,
          dt_inicial: null,
          dt_final: null
        },
        dependencies: {
          participantes: [],
          origens: [],
          itensFiltroListaPresenca: [
            {
              text: 'Sim',
              value: true
            },
            {
              text: 'Não',
              value: false
            }
          ],
          itensFiltroFinalizada: [
            {
              text: 'Sim',
              value: true
            },
            {
              text: 'Não',
              value: false
            }
          ]
        },
        relatorioPdfBase64: '',
        mostrarPickerDtInicial: false,
        mostrarPickerDtFinal: false
      }
    },
    async mounted () {
      this.filters.dt_inicial = this.dateFormat(this.momentNow().startOf('month'), 'en')
      this.filters.dt_final = this.dateFormat(this.momentNow().endOf('month'), 'en')

      await this.getDependencies()
      bus.$emit('hide-loader')
    },
    computed: {
      ...mapGetters(['permissions']),
      canGerarRelatorioPdf () {
        return this.permissions.some((item) => item === 'relatorio.atas-por-participante')
      },
      canGerarRelatorioCsv () {
        return this.permissions.some((item) => item === 'relatorio.csv-atas-por-participante')
      },
      dt_inicial_formatada () {
        return this.filters.dt_inicial ? this.dateFormat(this.filters.dt_inicial, 'br') : ''
      },
      dt_final_formatada () {
        return this.filters.dt_final ? this.dateFormat(this.filters.dt_final, 'br') : ''
      }
    },
    methods: {
      async getDependencies () {
        const domains = [{ domain: 'participante' }, { domain: 'origem' }]

        DataService.get(domains).then(result => {
          this.dependencies.participantes = result.participantes || []
          this.dependencies.origens = result.origens
        })
      },
      onFilter () {
        this.isLoadingData = true
        bus.$emit('show-loader')

        const data = {
          ref_pessoa_participante: this.filters.participante,
          ref_origem: this.filters.origem,
          fl_lista_presenca: this.filters.lista_presenca,
          fl_finalizada: this.filters.finalizada,
          dt_inicial: this.filters.dt_inicial,
          dt_final: this.filters.dt_final
        }

        relatorioService.gerarRelatorioAtasPorParticipante(data)
          .then(async response => {
            this.relatorioPdfBase64 = 'data:application/pdf;base64, ' + response

            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)

            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
      },
      onFilterCsv () {
        this.isLoadingData = true
        bus.$emit('show-loader')

        const data = {
          ref_pessoa_participante: this.filters.participante,
          ref_origem: this.filters.origem,
          fl_lista_presenca: this.filters.lista_presenca,
          fl_finalizada: this.filters.finalizada,
          dt_inicial: this.filters.dt_inicial,
          dt_final: this.filters.dt_final
        }

        relatorioService.gerarRelatorioCsvAtasPorParticipante(data)
          .then(async response => {
            const blob = decodeURIComponent(escape(atob(response.arquivo)))
            const url = window.URL.createObjectURL(new Blob([blob], { type: 'data:text/csv;base64' }))
            const link = document.createElement('a')

            link.href = url
            link.setAttribute('download', response.nome_arquivo)
            document.body.appendChild(link)
            link.click()
            link.remove()

            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)

            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
      },
      onFilterClean () {
        this.filters = {
          participante: '',
          origem: '',
          lista_presenca: null,
          finalizada: null,
          dt_inicial: this.dateFormat(this.momentNow().startOf('month'), 'en'),
          dt_final: this.dateFormat(this.momentNow().endOf('month'), 'en')
        }
      },
      filtrarParticipante (item, queryText, itemText) {
        return item.nome.toLowerCase().indexOf(queryText.toString().toLowerCase()) > -1
      }
    }
  }
</script>

<template>
  <div class="page-content">

    <app-page-header></app-page-header>

    <div class="list-component elevation-1 d-flex flex-direction-column fill-height">
      <v-form :model="filters" @submit.prevent="onFilter">
        <v-card class="list-filters-card" :flat="true">
          <v-card-title class="grey lighten-2">
            <div class="flex-header">
              <div class="flex-header-title">
                <i class="fas fa-filter"></i>
                <span class="pl-2 font-weight-medium">Filtros</span>
              </div>
              <div class="flex-header-actions">
                <v-btn type="submit" color="green lighten-1 white--text" small v-if="canGerarRelatorioPdf">
                  <i class="fas fa-file-pdf"></i>
                  <span class="pl-2">Gerar PDF</span>
                </v-btn>
                <v-btn color="green lighten-1 white--text" @click="onFilterCsv" small v-if="canGerarRelatorioCsv">
                  <i class="fas fa-file-csv"></i>
                  <span class="pl-2">Gerar CSV</span>
                </v-btn>
                <v-btn @click="onFilterClean" small>
                  <i class="fas fa-times-circle"></i>
                  <span class="pl-2">Limpar Filtros</span>
                </v-btn>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <v-container class="list-filters-container" grid-list-md>
              <v-layout row wrap align-content-end>
                <v-flex xs12 sm6 md4>
                  <v-autocomplete v-model="filters.participante"
                                  :items="dependencies.participantes"
                                  :filter="filtrarParticipante"
                                  item-text="nome"
                                  item-value="id"
                                  label="Participante"
                                  hide-no-data
                                  clearable></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-select v-model="filters.origem"
                            :items="dependencies.origens"
                            label="Origem"
                            item-text="nome"
                            item-value="id"
                            clearable></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-select v-model="filters.lista_presenca"
                            :items="dependencies.itensFiltroListaPresenca"
                            label="Lista de presença"
                            clearable
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-select v-model="filters.finalizada"
                            :items="dependencies.itensFiltroFinalizada"
                            label="Finalizada"
                            clearable
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-menu ref="pickerDtInicial"
                          :close-on-content-click="false"
                          v-model="mostrarPickerDtInicial"
                          :nudge-right="40"
                          lazy
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px">
                    <v-text-field slot="activator"
                                  v-model="dt_inicial_formatada"
                                  label="Data inicial"
                                  persistent-hint
                                  append-icon="event"
                                  @blur="filters.dt_inicial = dateFormat(dt_inicial_formatada, 'en')"/>
                    <v-date-picker v-model="filters.dt_inicial"
                                   @input="mostrarPickerDtInicial = false"
                                   locale="pt-br"/>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-menu ref="pickerDtFinal"
                          :close-on-content-click="false"
                          v-model="mostrarPickerDtFinal"
                          :nudge-right="40"
                          lazy
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px">
                    <v-text-field slot="activator"
                                  v-model="dt_final_formatada"
                                  label="Data final"
                                  persistent-hint
                                  append-icon="event"
                                  @blur="filters.dt_final = dateFormat(dt_final_formatada, 'en')"/>
                    <v-date-picker v-model="filters.dt_final"
                                   @input="mostrarPickerDtFinal = false"
                                   locale="pt-br"/>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>

      <div class="list-filters d-flex fill-height">
        <div class="iframe-pdf flex-grow">
          <iframe :src="relatorioPdfBase64"></iframe>
        </div>
      </div>

    </div>

  </div>
</template>
